/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    twitch: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.857 0L1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0zm9.714 7.429l-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142z"/><path pid="1" d="M11.857 3.143h-1.143V6.57h1.143zm-3.143 0H7.571V6.57h1.143z"/>',
    },
});
